import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import cn from 'classnames';

import styles from './menu.module.css';
import { useStore } from '../../store/app';
import { Prompt, PromptProps } from '../prompt/prompt';
import { Modal, Stage } from '../../types';
import { Button } from '../button/button';

export const Menu: FC<{ direction?: 'up' | 'down' }> = observer(
  ({ direction = 'down' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [promptConfig, setPromptConfig] = useState<PromptProps>();
    const toggleIsOpen = () => setIsOpen(!isOpen);
    const {
      restart,
      logout,
      setModal,
      isSoundEnabled,
      toggleSound,
      setStage,
      setFinal,
      isAdmin,
    } = useStore();
    const handleRestart = () => {
      setPromptConfig({
        text:
          'В этом случае ты вернешься к выбору персонажа и начнешь сначала.',
        onAccept: restart,
        onDecline: () => setPromptConfig(undefined),
      });
      toggleIsOpen();
    };
    const handleLogout = () => {
      setPromptConfig({
        text: 'Точно хочешь выйти? В этом случае данные игры не сохранятся.',
        onAccept: logout,
        onDecline: () => setPromptConfig(undefined),
      });
      toggleIsOpen();
    };
    const handleGuide = () => {
      setModal(Modal.GUIDE);
      toggleIsOpen();
    };
    return (
      <>
        <div className={styles.menuWrapper}>
          <Button
            className={styles.menuButton}
            variant="menu"
            onClick={toggleIsOpen}
          >
            ⚙
          </Button>
          {isOpen && (
            <div className={cn(styles.menuContainer, styles[direction])}>
              <div className={styles.menu}>
                <Button
                  onClick={toggleSound}
                  variant={isSoundEnabled ? 'success' : 'danger'}
                >
                  Звук
                </Button>
                <Button onClick={handleGuide}>Гайд</Button>
                <Button onClick={handleRestart}>Рестарт</Button>
                <Button onClick={handleLogout}>Выйти</Button>
                {isAdmin && (
                  <>
                    <Button onClick={() => setStage(Stage.RATING)}>
                      рейтинг
                    </Button>
                    <Button
                      onClick={() => {
                        setFinal({
                          score: 123,
                          text:
                            'Твое поселение успешно пережило наступление Ледника. При помощи развитых технологий он был удачно встроен в инфраструктуру поселения. Из Ледника устроили огромные ледяные и водные горки. Жители поселения на тысячелетия обеспечили себя чистой питьевой водой и умопомрачительными развлечениями.',
                          status: 'Привет, как дела, это заголовок',
                          position: 5234,
                        });
                      }}
                    >
                      финал
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {promptConfig && <Prompt {...promptConfig} />}
      </>
    );
  }
);
