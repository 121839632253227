import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useStore } from '../../store/app';
import { ModalWithPaging } from '../modal-with-paging/modal-with-paging';

export const Guide = observer(() => {
  const { setModal, start, pause } = useStore();
  const handleHide = () => {
    start();
    setModal(undefined);
  };
  useEffect(() => {
    pause();
  }, []);
  return (
    <ModalWithPaging
      title="Гайд"
      closable
      onHide={handleHide}
      pages={[
        <>
          <b>Общее</b>
          <br />
          Одна игра длится 5 минут. В любой момент ты можешь поставить игру на
          паузу и вернуться к ней позже (иконка плей рядом с таймером)
          <br />
          <br />
          <b>Задача игры</b>
          <br />
          Поднять на максимум уровень цивилизации (иконка со стрелками), чтобы
          спастись от Ледника. Для этого ты можешь прокачать новые бизнесы
          Росатома (строения) и просчитать стратегию, как лучше реагировать на
          то или иное событие в поселении.
        </>,
        <>
          <b>Прокачка строений</b>
          <br />
          Ты можешь улучшить одно, несколько или все здания:
          <br />
          - Платформу «умный город» (IT-технологии)
          <br />
          - Полосатый цех (аддитивные технологии 3D печати)
          <br />
          - Бакланий ветропарк (ветроэнергетика)
          <br />
          - Знахарский пункт (ядерная медицина)
          <br />
          - Экокоммуну ленивцев (обращение с отходами 1 и 2 класса)
          <br />
        </>,
        <>
          У каждого строения может быть 3 уровня прокачки, где 1 уровень −
          стартовый, 3 − максимальный. Каждый новый уровень строения принесёт
          поселению прирост определенных ресурсов, а также единоразово поднимет
          уровень цивилизации. За 2ой уровень каждого строения ты получишь 200
          очков цивилизации, за 3ий − по 400 очков. Условия по улучшению
          строений можно изучить, кликнув на него. Как только в строении станет
          доступно обновление для перехода на новый уровень, рядом с ним
          загорится зелёная стрелка. Выбор персонажа при регистрации (Медведь,
          Песец или Нерпа) может давать бонус на старте − 2 уровень одного из
          строений.
        </>,
        <>
          <b>Ресурсы</b>
          <br />
          В игре ты можешь зарабатывать и тратить ресурсы: <br />
          - еду (иконка индейки)
          <br />
          - научные технологии (иконка микроскопа)
          <br />
          - популяцию (иконка мышки)
          <br />
          <br />
          Главный игровой показатель − <strong>уровень цивилизации</strong>{' '}
          (иконка со стрелками). Чем он выше, тем больше шансов на победу.
          Заработать и потратить ресурсы, а также повлиять на уровень
          цивилизации можно, улучшая строения или в ходе игровых событий.
          <br />
        </>,
        <>
          <b>События</b>
          <br />
          Каждые 15 секунд в поселении происходят события различного характера.
          Ты не можешь закрыть окно события, пока не выберешь один из вариантов
          реакции на него. События могут прибавлять или уменьшать количество
          накопленных ресурсов. Стоимость твоего выбора в ресурсах будет
          отражаться в окне вариантов действий на событие.
          <br />
          <br />
          <b>Рынок</b>
          <br />
          Для улучшения строений или во время выбора действий на то или иное
          событие ты можешь воспользоваться рынком.
        </>,
        <>
          <b>Карта и подсказки</b>
          <br />
          Кажется, просчитать свою стратегию здесь просто нереально? Да, но нет.
          Если будешь достаточно внимательным, то путешествуя по дополнительным
          объектам карты, можешь увидеть подсказки к победе. У жителей каждого
          строения есть своё характерное поведение: например, руководствуясь
          тем, как обычно себя ведут айти совы, можно управлять связанными с
          ними событиями так, чтобы твои действия успешнее влияли на уровень
          цивилизации.
        </>,
        <>
          <b>Победа</b>
          <br />
          Подними на максимум уровень своей цивилизации и поборись за
          возможность увидеть северное сияние с побережья Северного Ледовитого
          океана или получить один из 30 эксклюзивных наборов от Росатома.
          Играть можно несколько раз, все результаты сохранятся в рейтинге
          игроков.
        </>,
      ]}
    />
  );
});
