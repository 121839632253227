import React from 'react';

import { observer } from 'mobx-react-lite';

import styles from './mobile-bottom-bar.module.css';
import { Rating } from '../rating/rating';
import { Timer } from '../timer/timer';
import { MarketButton } from '../market-button/market-button';
import { Menu } from '../menu/menu';
import { useStore } from '../../store/app';

export const MobileBottomBar = observer(() => {
  const { score } = useStore();
  return (
    <div className={styles.wrapper}>
      <div className={styles.bottomBar}>
        <div className={styles.leftTop}>
          <Rating score={score} isMobile />
          <Timer isMobile />
        </div>
        <div className={styles.rightTop}>
          <MarketButton />
          <Menu direction="up" />
        </div>
      </div>
    </div>
  );
});
