import React from 'react';
import axios from 'axios';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { io, Socket } from 'socket.io-client';

import {
  CharacterId,
  Config,
  Event,
  Final,
  Game,
  Modal,
  ResourceId,
  ServerToClientEvents,
  Stage,
  User,
} from '../types';
import { ApiService } from '../service/api-service';
import { Building } from '../types/building';
import { getStorageItem, setStorageItem } from '../helpers';

export type AppStoreInitialState = {
  stage: Stage;
  gameState?: Game;
};

const STORAGE_KEY = 'rosatom-game-user';

export class AppStore {
  isAdmin = window.location.search.includes('admin');

  @observable stage: Stage = Stage.LANDING;
  @observable modal?: Modal;
  @observable user?: User;
  @observable gameState?: Game;
  @observable openedBuilding?: Building;
  @observable currentEvent?: Event;
  @observable final?: Final;

  @observable config?: Config;
  @observable isInitialized = false;
  @observable isFetching = false;
  @observable isSoundEnabled = false;
  @observable audio: HTMLAudioElement;

  private api: ApiService;
  private socket?: Socket<ServerToClientEvents>;

  constructor(initialState?: AppStoreInitialState) {
    if (initialState) {
      runInAction(() => {
        this.stage = initialState.stage;
        this.gameState = initialState.gameState;
      });
    }

    this.init();
  }

  async init() {
    reaction(
      () => this.sessionId,
      sessionId => {
        if (sessionId) {
          this.configureSocket();
          this.listenSocket();
        } else {
          this.socket?.close();
        }
      }
    );

    reaction(
      () => this.currentEvent,
      currentEvent => {
        if (currentEvent && this.modal !== Modal.EVENT) {
          this.pause();
          this.setModal(Modal.EVENT);
        }
      }
    );

    reaction(
      () => this.openedBuilding,
      openedBuilding => {
        if (openedBuilding) {
          this.pause();
          this.setModal(Modal.BUILDING);
        } else {
          this.start();
          this.setModal(undefined);
        }
      }
    );

    reaction(
      () => this.isSoundEnabled,
      isSoundEnabled => {
        if (isSoundEnabled) {
          this.audio?.play();
        } else {
          this.audio?.pause();
        }
      }
    );

    reaction(
      () => this.final,
      final => {
        if (final && this.stage !== Stage.FINAL) {
          this.setStage(Stage.FINAL);
        }
      }
    );

    await this.configureAPI();
    this.initAudio();
    await this.restoreSession();
    this.setIsInitialized(true);
  }

  initAudio = async () => {
    return new Promise(resolve => {
      this.audio = new Audio('/sound.mp3');
      this.audio.volume = 0.25;
      this.audio.loop = true;
      this.audio.oncanplaythrough = () => resolve(this.audio);
    });
  };

  async fetchConfig() {
    const { data } = await axios.get<Config>('/config.json');

    runInAction(() => {
      this.config = data;
    });
  }

  async configureAPI() {
    await this.fetchConfig();

    if (!this.config?.API_URL) {
      throw new Error('Can not create api service beacuse of empty API_URL');
    }

    this.api = new ApiService(this.config.API_URL);
  }

  configureSocket() {
    if (!this.sessionId) {
      throw new Error('Can not open socket beacuse of empty sessionId');
    }
    if (!this.config?.WEBSOCKET_API_URL) {
      throw new Error('Can not open socket beacuse of empty WEBSOCKET_API_URL');
    }

    this.socket = io(this.config.WEBSOCKET_API_URL, {
      query: { id: this.sessionId },
    });
  }

  listenSocket() {
    this.socket?.on('game', data => {
      this.setGameState(data);

      if (data.event) {
        this.setCurrentEvent(data.event);
      }

      if (data.final) {
        this.setFinal(data.final);
      }
    });
  }

  @computed
  get sessionId() {
    return this.gameState?.id;
  }

  @computed
  get time() {
    return new Date((this.gameState?.timer || 0) * 1000)
      .toISOString()
      .substring(15, 19);
  }

  @computed
  get isPlay() {
    return !this.gameState?.isPaused;
  }

  @computed
  get score() {
    return this.gameState?.score || 0;
  }

  @computed
  get resources() {
    return this.gameState?.resources;
  }

  @computed
  get marketRate() {
    return this.gameState?.marketRate;
  }

  @computed
  get characterId() {
    return this.gameState?.character;
  }

  @computed
  get buildings() {
    return this.gameState?.buildings;
  }

  @computed
  get isRulesOpened() {
    return this.modal === Modal.RULES;
  }

  @action
  setModal = (value?: Modal) => {
    this.modal = value;
  };

  @action
  setGameState = (value?: Game) => {
    this.gameState = value;
  };

  @action
  setIsInitialized = (value: boolean) => {
    this.isInitialized = value;
  };

  @action
  setIsFetching = (value: boolean) => {
    this.isFetching = value;
  };

  @action
  setIsSoundEnabled = (value: boolean) => {
    this.isSoundEnabled = value;
  };

  @action
  setOpenedBuilding = (value?: Building) => {
    this.openedBuilding = value;
  };

  @action
  setStage = (value: Stage) => {
    this.stage = value;
  };

  @action
  setUser = (value: User) => {
    this.user = value;
  };

  @action
  setCurrentEvent = (value?: Event) => {
    this.currentEvent = value;
  };

  @action
  setFinal = (value?: Final) => {
    this.final = value;
  };

  saveSession(data?: { email: string; name: string; game?: Game }) {
    setStorageItem(STORAGE_KEY, data ? JSON.stringify(data) : '');
  }

  async restoreSession() {
    const savedSession = getStorageItem(STORAGE_KEY);
    if (!savedSession) {
      return;
    }

    const savedSessionData: Partial<User & { game?: Game }> = JSON.parse(
      savedSession
    );
    const { email, name, game } = savedSessionData;

    try {
      if (email && name && game?.id) {
        const { data } = await this.api.check(game.id);

        this.setUser({ email, name });
        this.setGameState(data);
        if (data.event) {
          this.setCurrentEvent(data.event);
        }
        if (data.final) {
          this.setFinal(data.final);
        } else {
          this.setStage(Stage.GAME);
        }
        this.toggleSound();
      }
    } catch (error) {
      console.log(error);
    }
  }

  registrate = async ({
    email,
    name,
    characterId,
  }: {
    email: string;
    name: string;
    characterId: CharacterId;
  }) => {
    try {
      this.setIsFetching(true);
      const { data } = await this.api.registrate(email, name, characterId);
      this.setGameState(data);
      this.setStage(Stage.GAME);
      this.setModal(Modal.RULES);
      this.setIsSoundEnabled(true);
      this.saveSession({ email, name, game: data });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  pause = async () => {
    if (!this.sessionId) {
      throw new Error('Can not pause beacuse of empty sessionId');
    }

    if (!this.isPlay) {
      return;
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.pause(this.sessionId);
      if (data.error) {
        console.log(data.error);
        return;
      }

      this.setGameState(data);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  start = async () => {
    if (!this.sessionId) {
      throw new Error('Can not start beacuse of empty sessionId');
    }

    if (this.isPlay) {
      return;
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.start(this.sessionId);
      if (data.error) {
        console.log(data.error);
        return;
      }

      this.setGameState(data);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  toggleGameState = () => {
    if (this.isPlay) {
      this.pause();
    } else {
      this.start();
    }
  };

  toggleSound = () => {
    this.setIsSoundEnabled(!this.isSoundEnabled);
  };

  exchange = async (from: ResourceId, to: ResourceId, value: number) => {
    if (!this.sessionId) {
      throw new Error('Can not start beacuse of empty sessionId');
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.exchange(this.sessionId, from, to, value);
      if (data.error) {
        throw new Error(data.error);
      }
      this.setGameState(data);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  logout = () => {
    runInAction(() => {
      this.user = undefined;
      this.stage = Stage.LANDING;
      this.gameState = undefined;
      this.isSoundEnabled = false;
    });
    this.saveSession();
  };

  restart = () => {
    runInAction(() => {
      this.stage = Stage.REGISTRATION;
      this.gameState = undefined;
      this.isSoundEnabled = false;
    });
    this.saveSession(this.user);
  };

  openBuilding = async (id: string) => {
    if (!this.sessionId) {
      throw new Error('Can not start beacuse of empty sessionId');
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.building(this.sessionId, id);
      this.setOpenedBuilding(data);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  upgradeBuilding = async () => {
    if (!this.sessionId) {
      throw new Error('Can not upgrade building beacuse of empty sessionId');
    }

    const id = this.openedBuilding?.id;

    if (!id) {
      throw new Error('Can not upgrade building beacuse of empty id');
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.upgrade(this.sessionId, id);
      if (data.error) {
        throw new Error(data.error);
      }
      this.setGameState(data);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    } finally {
      this.setIsFetching(false);
    }
  };

  putEventAnswer = async (answerId: number) => {
    if (!this.sessionId) {
      throw new Error('Can not answer beacuse of empty sessionId');
    }

    const id = this.currentEvent?.id;

    if (!id) {
      throw new Error('Can not answer beacuse of empty event id');
    }

    try {
      this.setIsFetching(true);
      const { data } = await this.api.answer(this.sessionId, id, answerId);
      const { text, resources, game, error } = data;

      if (error) {
        throw new Error(error);
      }

      this.setGameState(game);
      return { text, resources };
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }

    return;
  };

  fetchRating = async (params: { page: number; size: number }) => {
    const { page = 1, size = 10 } = params || {};

    try {
      this.setIsFetching(true);
      const { data } = await this.api.rating(page, size);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsFetching(false);
    }

    return;
  };

  openRating = () => {
    this.setStage(Stage.RATING);
    this.setModal(undefined);
  };
}

export const AppStoreContext = React.createContext<AppStore | null>(null);

export const useStore = () => {
  const appStore = React.useContext(AppStoreContext);

  if (!appStore) {
    throw new Error(
      'useAppStore must be used within a AppStoreContext.Provider.'
    );
  }

  return appStore;
};
