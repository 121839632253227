import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';

import { useStore } from '../../store/app';

import { Button } from '../button/button';

import styles from './prompt.module.css';

export type PromptProps = {
  text: string;
  onAccept: () => void;
  onDecline: () => void;
};

export const Prompt: FC<PromptProps> = observer(
  ({ text, onAccept, onDecline }) => {
    const { start, pause } = useStore();
    useEffect(() => {
      pause();
    });
    const handleDecline = () => {
      start();
      onDecline();
    };
    return (
      <div className={styles.wrapper}>
        <div className={styles.prompt}>
          {text}
          <div className={styles.buttons}>
            <Button
              className={styles.accept}
              variant="check"
              onClick={onAccept}
            >
              ✅
            </Button>
            <Button
              className={styles.decline}
              variant="cross"
              onClick={handleDecline}
            >
              ❌
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
