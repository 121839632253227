import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './input.module.css';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  isInvalid?: boolean;
};

export const Input: FC<Props> = ({
  type = 'text',
  className,
  onChange,
  value,
  autoComplete = 'off',
  isInvalid,
  ...rest
}) => {
  return (
    <input
      className={cn(styles.input, isInvalid && styles.invalid, className)}
      type={type}
      onChange={onChange}
      value={value}
      autoComplete={autoComplete}
      {...rest}
    />
  );
};
