import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import cn from 'classnames';

import styles from './resources.module.css';
import { useStore } from '../../store/app';
import { ResourceItem } from '../resource-item/resource-item';

export const Resources: FC<{ isMobile?: boolean }> = observer(
  ({ isMobile }) => {
    const { resources } = useStore();
    return (
      <div className={cn(styles.resources, isMobile && styles.mobile)}>
        {resources?.map(({ id, value, perSec }) => (
          <div key={id} className={styles.resourcesItem}>
            <ResourceItem
              key={id}
              resourceId={id}
              value={value}
              subValue={`+${perSec}/сек`}
              isMobile={isMobile}
            />
          </div>
        ))}
      </div>
    );
  }
);
