import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../store/app';

import styles from './map.module.css';
import { MapNPC } from '../map-npc/map-npc';
import { MapLocation } from '../map-location/map-location';
import { locations } from '../../constants/locations';
import { NPC } from '../../constants/npc';

export const Map = observer(() => {
  const { buildings, openBuilding, currentEvent, isRulesOpened } = useStore();
  return (
    <div className={styles.map}>
      {buildings?.map(({ id, level, canUpgrade }) => {
        return (
          <MapLocation
            onClick={
              isRulesOpened || currentEvent ? undefined : () => openBuilding(id)
            }
            key={id}
            name={locations[id].name}
            level={level}
            canUpgrade={canUpgrade}
          />
        );
      })}
      {NPC.map(({ name, text, tooltipPosition }) => (
        <MapNPC
          key={name}
          name={name}
          text={text || 'какой-то текст'}
          tooltipPosition={tooltipPosition}
        />
      ))}
    </div>
  );
});
