import axios from 'axios';

import { CharacterId, Game, ResourceId, Resources, SessionId } from '../types';
import { Building } from '../types/building';
import { Rating } from '../types/rating';

type Response<T> = CommonResponse & T;

type CommonResponse = {
  error?: string;
};

export class ApiService {
  transport;

  constructor(baseURL: string) {
    this.transport = axios.create({
      baseURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  game = async () => this.transport.get('/game');

  check = async (id: SessionId) =>
    this.transport.get<Game>('/check', { params: { id } });

  registrate = (email: string, name: string, characterId: CharacterId) => {
    return this.transport.post<Game>('/reg', {
      email,
      name,
      character: characterId,
    });
  };

  pause = (id: SessionId) => {
    return this.transport.post<Response<Game>>('/pause', { id });
  };

  start = (id: SessionId) => {
    return this.transport.post<Response<Game>>('/start', { id });
  };

  answer = (id: SessionId, eventId: number, answerId: number) => {
    return this.transport.post<
      Response<{
        text: string;
        resources: Resources;
        game: Game;
      }>
    >('/answer', {
      id,
      event_id: eventId,
      answer_id: answerId,
    });
  };

  exchange = (
    id: SessionId,
    from: ResourceId,
    to: ResourceId,
    value: number
  ) => {
    return this.transport.post<Response<Game>>('/exchange', {
      id,
      from,
      to,
      value,
    });
  };

  building = (id: SessionId, buildingId: string) => {
    return this.transport.get<Building>('/building', {
      params: {
        id,
        buildingId,
      },
    });
  };

  upgrade = (id: SessionId, buildingId: string) => {
    return this.transport.post<Response<Game>>('/upgrade', {
      id,
      buildingId,
    });
  };

  rating = (page: number, size: number) => {
    return this.transport.get<{
      data: Rating;
      page: {
        hasNext?: boolean;
        hasPrevious?: boolean;
      };
    }>('/rating', {
      params: {
        page,
        size,
      },
    });
  };
}
