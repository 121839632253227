import React, { FC } from 'react';
import cn from 'classnames';

import styles from './tooltip.module.css';

export type TooltipPosition = 'bottom' | 'top';
export type TooltipProps = {
  tooltipPosition?: TooltipPosition;
  className?: string;
  offsetY?: number;
  offsetX?: number;
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  tooltipPosition = 'top',
  className,
  offsetY = 10,
  offsetX = 20,
}) => {
  const style =
    tooltipPosition === 'top'
      ? {
          bottom: `calc(100% + ${offsetY ? `${offsetY}px` : ''})`,
          left: offsetX,
        }
      : {
          bottom: 'auto',
          top: `calc(100% + ${offsetY ? `${offsetY}px` : ''})`,
        };
  return (
    <div
      className={cn(styles.tooltip, styles[tooltipPosition], className)}
      style={style}
    >
      {children}
    </div>
  );
};
