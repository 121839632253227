import { Resources } from '../types';

export const isEnoughValue = (
  resources: Resources,
  requirements: Resources
) => {
  for (const resource of resources) {
    const item = requirements.find(item => resource.id === item.id);

    if (!item) {
      throw new Error('Can not find requirement.');
    }

    if (resource.value < item?.value) {
      return false;
    }
  }
  return true;
};
