import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import styles from './landing-page.module.css';
import { useStore } from '../../store/app';
import { Stage } from '../../types';
import { conditions, vacancies, voteRosatom } from '../../constants/links';

export const LandingPage = observer(() => {
  const { setStage } = useStore();
  const page = useRef(null);
  const pageRef2 = useRef<HTMLDivElement>(null);
  const pageRef3 = useRef<HTMLDivElement>(null);
  const handleClickPlay = () => {
    setStage(Stage.REGISTRATION);
  };
  const handleClickRating = () => {
    setStage(Stage.RATING);
  };
  return (
    <div className={styles.landingWrapper}>
      <div ref={page} className={styles.landing}>
        <div className={cn(styles.page, styles.page1)}>
          <div className={styles.age} />
          <button className={styles.playButton} onClick={handleClickPlay} />
          <button
            className={styles.nextButton}
            onClick={() => {
              pageRef2?.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
        <div ref={pageRef2} className={cn(styles.page, styles.page2)}>
          <button
            className={cn(styles.playButton, styles.playButton2)}
            onClick={handleClickPlay}
          />
          <button
            className={styles.nextButton}
            onClick={() => {
              pageRef3?.current?.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        </div>
        <div ref={pageRef3} className={cn(styles.page, styles.page3)}>
          <div className={styles.buttons}>
            <a
              href={voteRosatom}
              target="_blank"
              rel="noreferrer"
              className={styles.voteRosatom}
            />
            <button className={styles.rating} onClick={handleClickRating} />
          </div>
          <div className={styles.footer}>
            <div className={styles.left}>© Росатом 2022 | VILLKA </div>
            <div className={styles.right}>
              <a href={conditions} target="_blank" rel="noreferrer">
                Условия розыгрыша
              </a>
              <a href={vacancies} target="_blank" rel="noreferrer">
                Вакансии
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
