import React, { FC } from 'react';

import styles from './characters.module.css';
import { CharacterId } from '../../types';
import { charactersData } from '../../constants/characters';
import { Avatar } from '../avatar/avatar';

const charactersMap = Object.keys(charactersData).map(key => ({
  id: Number(key),
  ...charactersData[key as keyof typeof charactersData],
}));

export const Characters: FC<{
  characterId?: CharacterId;
  onChange: (id: CharacterId) => void;
}> = ({ characterId, onChange }) => {
  return (
    <div>
      <div className={styles.characters}>
        {charactersMap.map(({ id, name }) => (
          <button
            key={id}
            type="button"
            className={styles.character}
            onClick={() => onChange(id)}
          >
            <Avatar id={id} selected={id === characterId} />
            <div className={styles.characterName}>{name}</div>
          </button>
        ))}
      </div>
      {characterId && (
        <div className={styles.description}>
          {
            charactersData[String(characterId) as keyof typeof charactersData]
              .description
          }
        </div>
      )}
    </div>
  );
};
