import React from 'react';

import styles from './mobile-map.module.css';
import { Map } from '../map/map';

export const MobileMap = () => {
  return (
    <div className={styles.mobileMap}>
      <Map />
    </div>
  );
};
