export const charactersData = {
  '1': {
    name: 'Нерпа',
    imgSrc: '#',
    description:
      'Нежная снежинка. Романтичная и возвышенная. Шарит за экологию и дружит с панк-ленивцами (что бы это ни значило).',
  },
  '2': {
    name: 'Песец',
    imgSrc: '#',
    description:
      'Безбашенный тип. Вокруг него всегда творческий хаос и беспорядок. Его кореша – бакланы, и он кое-что понимает в силе ветра.',
  },
  '3': {
    name: 'Медведь',
    imgSrc: '#',
    description:
      'Столп самоуверенности и решительности. Только он может справиться с повернутыми на ядерной медицине саблезубыми корги.',
  },
};
