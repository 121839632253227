import React from 'react';

import { observer } from 'mobx-react-lite';

import styles from './topbar.module.css';
import { Avatar } from '../avatar/avatar';
import { Rating } from '../rating/rating';
import { Timer } from '../timer/timer';
import { Resources } from '../resources/resources';
import { MarketButton } from '../market-button/market-button';
import { Menu } from '../menu/menu';
import { useStore } from '../../store/app';

export const Topbar = observer(() => {
  const { characterId, score } = useStore();
  return (
    <div className={styles.topbar}>
      <div className={styles.leftTop}>
        <Avatar id={characterId!} />
        <Rating score={score} />
        <div>
          <Timer />
        </div>
      </div>
      <div className={styles.resources}>
        <Resources />
      </div>
      <div className={styles.rightTop}>
        <MarketButton />
        <Menu />
      </div>
    </div>
  );
});
