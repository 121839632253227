import React from 'react';

import { observer } from 'mobx-react-lite';

import styles from './mobile-topbar.module.css';
import { Avatar } from '../avatar/avatar';
import { Resources } from '../resources/resources';
import { useStore } from '../../store/app';

export const MobileTopbar = observer(() => {
  const { characterId } = useStore();
  return (
    <div className={styles.wrapper}>
      <div className={styles.topbar}>
        <div className={styles.leftTop}>
          <Avatar id={characterId!} isMobile />
        </div>
        <div className={styles.resources}>
          <Resources isMobile />
        </div>
      </div>
    </div>
  );
});
