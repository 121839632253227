import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '../../store/app';
import { Modal } from '../../types';
import { Button } from '../button/button';

export const MarketButton = observer(() => {
  const {
    setModal,
    modal,
    pause,
    isPlay,
    currentEvent,
    isRulesOpened,
  } = useStore();
  const handleClick = () => {
    if (isPlay) {
      pause();
    }

    setModal(
      modal === Modal.MARKET
        ? currentEvent
          ? Modal.EVENT
          : undefined
        : Modal.MARKET
    );
  };
  return (
    <Button disabled={isRulesOpened} onClick={handleClick}>
      Рынок
    </Button>
  );
});
