import React, { FC } from 'react';
import cn from 'classnames';

import styles from './map-npc.module.css';
import { Tooltip, TooltipPosition } from '../tooltip/tooltip';

export type Name =
  | 'alien'
  | 'mammoth'
  | 'cave'
  | 'robot'
  | 'selfie'
  | 'lochness';

type Props = {
  name: Name;
  onClick?: (name: Name) => void;
  text: string;
  tooltipPosition?: TooltipPosition;
};

export const MapNPC: FC<Props> = ({
  name,
  onClick,
  text = 'zozo',
  tooltipPosition = 'top',
}) => {
  return (
    <div
      onClick={() => onClick?.(name)}
      className={cn(styles.npc, styles[name])}
    >
      <Tooltip className={styles.tooltip} tooltipPosition={tooltipPosition}>
        {text}
      </Tooltip>
    </div>
  );
};
