import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from '../store/app';
import { Modal } from '../types';
import { Building } from '../components/building/building';
import { Event } from '../components/event/event';
import { Guide } from '../components/guide/guide';
import { Market } from '../components/market/market';
import { Rules } from '../components/rules/rules';

export const Modals = observer(() => {
  const { modal } = useStore();

  switch (modal) {
    case Modal.MARKET:
      return <Market />;
    case Modal.EVENT:
      return <Event />;
    case Modal.BUILDING:
      return <Building />;
    case Modal.GUIDE:
      return <Guide />;
    case Modal.RULES:
      return <Rules />;
  }

  return null;
});
