import React, { FC } from 'react';
import cn from 'classnames';

import styles from './modal.module.css';
import { Button } from '../button/button';

type ModalProps = {
  size?: 'small' | 'large';
  closable?: boolean;
  onHide?: () => void;
  align?: 'center' | 'right';
  withAnimals?: boolean;
  className?: string;
  block?: boolean;
};

export const Modal: FC<ModalProps> = ({
  children,
  closable = false,
  size = 'small',
  onHide,
  align = 'right',
  withAnimals,
  className,
  block,
}) => {
  return (
    <div
      className={cn(
        styles.modal,
        styles[size],
        styles[align],
        block && styles.block
      )}
    >
      {withAnimals && <div className={styles.animals} />}
      {closable && (
        <Button
          type="button"
          variant="cross"
          className={styles.close}
          onClick={onHide}
        >
          X
        </Button>
      )}
      <div className={cn(styles.content, className)}>{children}</div>
    </div>
  );
};

export const ModalTitle: FC<{ className?: string }> = ({
  children,
  className,
}) => <div className={cn(styles.title, className)}>{children}</div>;

export const ModalText: FC = ({ children }) => (
  <div className={styles.text}>{children}</div>
);

export const ModalActions: FC = ({ children }) => (
  <div className={styles.actions}>{children}</div>
);
