import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader/root';
import { configure } from 'mobx';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import { AppStoreContext, AppStore, AppStoreInitialState } from './store/app';
import { Pages } from './pages/pages';
import './styles/vars.css';
import { Game, Stage } from './types';

configure({ enforceActions: 'observed' });

// TODO: remove
// @ts-ignore
const _: AppStoreInitialState = {
  stage: Stage.GAME,
  gameState: {
    id: 'test',
    character: 1,
    isPaused: false,
    resources: [
      {
        id: 'r1',
        perSec: 5,
        value: 50,
      },
      {
        id: 'r2',
        perSec: 2,
        value: 150,
      },
      {
        id: 'r3',
        perSec: 7,
        value: 300,
      },
    ],
    event: {
      title: 'Вот это ивентище',
      text: 'Небольшой дескрипш',
      id: 123,
      answers: [
        {
          id: 1,
          requirements: [
            { id: 'r1', value: 10 },
            { id: 'r2', value: 20 },
            { id: 'r3', value: 30 },
          ],
          title: 'Или меня',
        },
        {
          id: 2,
          requirements: [
            { id: 'r1', value: 10 },
            { id: 'r2', value: 20 },
            { id: 'r3', value: 30 },
          ],
          title: 'Выбери меня',
        },
        {
          id: 3,
          requirements: [
            { id: 'r1', value: 10 },
            { id: 'r2', value: 20 },
            { id: 'r3', value: 30 },
          ],
          title: 'А может лучше меня',
        },
      ],
    },
  } as Game,
};

export const App = hot(() => {
  const store = useMemo(() => new AppStore(), []);

  // useEffect(() => {
  //   store.start();
  //   return () => {
  //     store.cleanUp();
  //   };
  // }, [store]);
  return (
    <AppStoreContext.Provider value={store}>
      <>
        <Helmet>
          <title>АрктикА</title>
        </Helmet>
        <Pages />
      </>
    </AppStoreContext.Provider>
  );
});
