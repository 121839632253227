import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import cn from 'classnames';

import styles from './resource-item-button.module.css';

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isSelected?: boolean;
};

export const ResourceItemButton: FC<Props> = ({
  children,
  isSelected,
  ...rest
}) => {
  return (
    <button
      className={cn(styles.button, isSelected && styles.selected)}
      {...rest}
    >
      {children}
    </button>
  );
};
