import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Button } from '../components/button/button';

import { Characters } from '../components/characters/characters';

import { Modal, ModalActions, ModalTitle } from '../components/modal/modal';

import { RegistrationForm } from '../components/registration-form/registration-form';
import { useStore } from '../store/app';
import { CharacterId, User } from '../types';

enum State {
  'REGISTRATION',
  'CHARACTER',
}

export const Registration = observer(() => {
  const { registrate, setUser, user } = useStore();
  const [characterId, setCharacterId] = useState<CharacterId>();
  const [state, setState] = useState<State>(
    user ? State.CHARACTER : State.REGISTRATION
  );
  const handleSubmitForm = async (values: User) => {
    setUser(values);
    setState(State.CHARACTER);
  };
  const handleSubmit = async () => {
    if (isSubmitAvailable) {
      await registrate({ ...user, characterId });
    }
  };
  const isSubmitAvailable = characterId && user;
  return (
    <Modal align="center" withAnimals>
      {state === State.REGISTRATION ? (
        <>
          <ModalTitle>Регистрация</ModalTitle>
          <RegistrationForm onSubmit={handleSubmitForm} />
        </>
      ) : (
        <>
          <ModalTitle>Выбери персонажа</ModalTitle>
          <Characters characterId={characterId} onChange={setCharacterId} />
          <ModalActions>
            <Button onClick={handleSubmit} disabled={!isSubmitAvailable}>
              Погнали!!!
            </Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
});
