import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import styles from './market.module.css';
import { useStore } from '../../store/app';
import { Modal, ModalActions, ModalTitle } from '../modal/modal';
import { ResourceId, Modal as ModalType } from '../../types';
import { Button } from '../button/button';
import { ResourceItem } from '../resource-item/resource-item';
import { ResourceItemButton } from '../resource-item-button/resource-item-button';
import { Exchange } from '../exchange/exchange';

export const Market = observer(() => {
  const {
    setModal,
    start,
    resources,
    marketRate,
    exchange,
    currentEvent,
  } = useStore();
  const [toSell, setToSell] = useState<ResourceId>();
  const [toBuy, setToBuy] = useState<ResourceId>();
  const [exchangeValue, setExchangeValue] = useState(0);
  const handleIncrease = () => {
    setExchangeValue(exchangeValue + (marketRate || 1));
  };
  const handleDecrease = () => {
    setExchangeValue(exchangeValue - 1);
  };
  const handleHide = () => {
    start();
    setModal(currentEvent ? ModalType.EVENT : undefined);
  };
  const canSubmit = toSell && toBuy && exchangeValue && marketRate;
  const handleSubmit = () => {
    if (canSubmit) {
      exchange(toSell, toBuy, Math.floor(exchangeValue / marketRate));
      setExchangeValue(0);
    }
  };
  const maxValue =
    (toSell && resources?.find(item => item.id === toSell)?.value) || 0;

  return (
    <Modal onHide={handleHide} closable>
      <ModalTitle>Рынок</ModalTitle>
      <div className={styles.resourcesColumns}>
        <div>
          <div className={styles.subtitle}>Продать</div>
          <div className={styles.resources}>
            {resources?.map(({ id, value }) => (
              <ResourceItemButton
                key={id}
                onClick={() => setToSell(id)}
                isSelected={id === toSell}
              >
                <ResourceItem
                  resourceId={id}
                  value={value}
                  subValue={`${marketRate} к 1`}
                />
              </ResourceItemButton>
            ))}
          </div>
        </div>
        <div>
          <div className={styles.subtitle}>Купить</div>
          <div className={styles.resources}>
            {resources?.map(({ id }) => (
              <ResourceItemButton
                key={id}
                onClick={() => setToBuy(id)}
                isSelected={id === toBuy}
              >
                <ResourceItem resourceId={id} />
              </ResourceItemButton>
            ))}
          </div>
        </div>
      </div>
      {toSell && toBuy && toSell !== toBuy && marketRate && (
        <Exchange
          fromResourceId={toSell}
          toResourceId={toBuy}
          multiplier={marketRate}
          maxValue={maxValue}
          value={exchangeValue}
          handleIncrease={handleIncrease}
          handleDecrease={handleDecrease}
          onChange={value => setExchangeValue(value)}
        />
      )}
      <ModalActions>
        <Button onClick={handleSubmit} disabled={!canSubmit}>
          Обменять
        </Button>
      </ModalActions>
    </Modal>
  );
});
