import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';

import styles from './rating-page.module.css';
import { useStore } from '../../store/app';
import { Button } from '../button/button';
import { Rating, RatingItem } from '../../types/rating';

const TableItem: FC<RatingItem> = ({ name, position, score }) => {
  return (
    <div className={styles.tableRow}>
      <div className={styles.position}>
        <div className={styles.tableCell}>{position}</div>
      </div>
      <div className={styles.name}>
        <div className={styles.tableCell}>{name}</div>
      </div>
      <div className={styles.score}>
        <div className={styles.tableCell}>{score}</div>
      </div>
    </div>
  );
};

export const RatingPage: FC<{ size?: number; perColumn?: number }> = observer(
  ({ size = 20, perColumn = 10 }) => {
    const { restart, fetchRating, isFetching } = useStore();
    const [pageInfo, setPageInfo] = useState<{
      number: number;
      hasNext?: boolean;
      hasPrevious?: boolean;
    }>({ number: 0 });
    const [data, setData] = useState<Rating>();
    const handlePrev = () => {
      setPageInfo({ ...pageInfo, number: pageInfo.number - 1 });
    };
    const handleNext = () => {
      setPageInfo({ ...pageInfo, number: pageInfo.number + 1 });
    };
    const loadData = async () => {
      const response = await fetchRating({ page: pageInfo.number, size });
      setData(response?.data);
      const { hasNext, hasPrevious } = response?.page || {};
      setPageInfo({ number: pageInfo.number, hasNext, hasPrevious });
    };

    useEffect(() => {
      loadData();
    }, [pageInfo.number]);

    return (
      <div className={styles.rating}>
        <div className={styles.top}>
          <button
            className={styles.prev}
            onClick={handlePrev}
            disabled={isFetching}
            style={{ visibility: pageInfo.hasPrevious ? 'visible' : 'hidden' }}
          />
          Рейтинг игроков
          <button
            className={styles.next}
            onClick={handleNext}
            disabled={isFetching}
            style={{ visibility: pageInfo.hasNext ? 'visible' : 'hidden' }}
          />
        </div>
        <div className={styles.table}>
          <div className={styles.leftColumn}>
            {data?.slice(0, perColumn).map(item => (
              <TableItem key={item.position} {...item} />
            ))}
          </div>
          <div className={styles.rightColumn}>
            {data?.slice(perColumn, size).map(item => (
              <TableItem key={item.position} {...item} />
            ))}
          </div>
          {}
        </div>
        <div className={styles.bottom}>
          <Button onClick={restart}>Играть</Button>
        </div>
      </div>
    );
  }
);
