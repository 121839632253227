import { observer } from 'mobx-react-lite';
import React from 'react';

import styles from './building.module.css';
import { Modal, ModalActions, ModalText, ModalTitle } from '../modal/modal';
import { useStore } from '../../store/app';
import { Button } from '../button/button';
import { ResourcesBlock } from '../resources-block/resources-block';
import { ResourceItem } from '../resource-item/resource-item';
import { isEnoughValue } from '../../utils/is-enough-value';

export const Building = observer(() => {
  const {
    setModal,
    start,
    upgradeBuilding,
    isFetching,
    openedBuilding,
    resources,
  } = useStore();
  const { title, text, upgrade, resourcesPerSec, level } = openedBuilding || {};
  const handleHide = () => {
    start();
    setModal(undefined);
  };
  const handleUpgrade = async () => {
    await upgradeBuilding();
    handleHide();
  };
  return (
    <Modal onHide={handleHide} closable>
      <ModalTitle className={styles.title}>
        {title}
        <div className={styles.level}>Уровень {level}</div>
      </ModalTitle>
      <ModalText>{text}</ModalText>
      <ModalTitle className={styles.subtitle}>Приносит</ModalTitle>
      {resourcesPerSec && (
        <div className={styles.requirements}>
          <ResourcesBlock
            resources={resourcesPerSec}
            valueMapper={(value: any) => `+${value}/сек`}
          />
        </div>
      )}
      {upgrade && (
        <div className={styles.upgrade}>
          <div className={styles.info}>
            <div>
              <div className={styles.subtitle}>Стоимость</div>
              <div className={styles.resources}>
                {upgrade.requirements.map(({ id, value }) => (
                  <ResourceItem
                    key={id}
                    resourceId={id}
                    value={value}
                    variant="small"
                  />
                ))}
              </div>
            </div>
            <div>
              <div className={styles.subtitle}>Что даст</div>
              <div className={styles.resources}>
                {upgrade.resourcesPerSec.map(({ id, value }) => (
                  <ResourceItem
                    key={id}
                    resourceId={id}
                    subValue={`+${value}/сек`}
                    variant="small"
                  />
                ))}
              </div>
            </div>
          </div>
          <ModalActions>
            <Button
              onClick={handleUpgrade}
              disabled={
                isFetching || !isEnoughValue(resources!, upgrade?.requirements)
              }
            >
              Улучшить
            </Button>
          </ModalActions>
        </div>
      )}
    </Modal>
  );
});
