import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import styles from './timer.module.scss';
import { useStore } from '../../store/app';
import { Tooltip } from '../tooltip/tooltip';

export const Timer: FC<{ isMobile?: boolean }> = observer(({ isMobile }) => {
  const { isPlay, time, toggleGameState, gameState } = useStore();
  const showBalloon = gameState?.timer ? gameState?.timer <= 10 : false;
  return (
    <div className={cn(styles.timer, isMobile && styles.mobile)}>
      <button
        type="button"
        className={cn(styles.play, !isPlay && styles.pause)}
        onClick={toggleGameState}
      />
      <div className={styles.board}>{time}</div>
      {showBalloon && (
        <Tooltip
          className={styles.tooltip}
          tooltipPosition={isMobile ? 'top' : 'bottom'}
          offsetY={isMobile ? 60 : 40}
          offsetX={isMobile ? -10 : 20}
        >
          Итак, my darling, Ледник уже здесь! Ну что, посмотрим на результат?
        </Tooltip>
      )}
    </div>
  );
});
