import React, { FC } from 'react';
import cn from 'classnames';

import styles from './rating.module.css';

export const Rating: FC<{
  score: number;
  isMobile?: boolean;
  isHuge?: boolean;
}> = ({ score, isMobile, isHuge }) => {
  return (
    <div
      className={cn(
        styles.rating,
        isMobile && styles.mobile,
        isHuge && styles.huge
      )}
    >
      <span className={styles.score}>{score}</span>
    </div>
  );
};
