import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import cn from 'classnames';

import styles from './button.module.css';

export type ButtonVariant =
  | 'defautl'
  | 'success'
  | 'danger'
  | 'cross'
  | 'check'
  | 'menu';
// | 'market'
// | 'guide'
// | 'restart'
// | 'exit'
// | 'sound'
// | 'menu'
// | 'super'
// | 'next'
// | 'ok'
// | 'understand'
// | 'upgrade'
// | 'exchange';

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
  HTMLButtonElement | HTMLAnchorElement
> & {
  variant?: ButtonVariant;
  href?: string;
  target?: HTMLAnchorElement['target'];
  rel?: HTMLAnchorElement['rel'];
  multiline?: boolean;
  noAction?: boolean;
};

const excludedVariants = ['cross', 'check', 'menu'];

export const Button: FC<Props> = ({
  type,
  className,
  children,
  onClick,
  variant,
  href,
  multiline,
  noAction,
  ...rest
}) => {
  const isExcludedVariant =
    variant && excludedVariants.includes(variant as any);
  const ButtonTag = noAction ? 'span' : href ? 'a' : 'button';
  return (
    //@ts-ignore
    <ButtonTag
      className={cn(
        styles.button,
        variant && styles[variant],
        isExcludedVariant && styles.excluded,
        multiline && styles.multiline,
        noAction && styles.noAction,
        className
      )}
      type={type}
      onClick={onClick}
      href={href}
      {...rest}
    >
      <span className={styles.bg} />
      {isExcludedVariant ? (
        <span className={cn(styles.icon, styles[variant])} />
      ) : (
        <span className={styles.contentWrapper}>{children}</span>
      )}
    </ButtonTag>
  );
};
