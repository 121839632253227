import { observer } from 'mobx-react-lite';
import React from 'react';

import { Layout } from '../components/layout/layout';
import { Loader } from '../components/loader/loader';
import { Map } from '../components/map/map';
import { Topbar } from '../components/topbar/topbar';
import { useStore } from '../store/app';
import { Stage } from '../types';
import { Modals } from './modals';
import { Registration } from './registration';
import { isMobile } from '../utils/is-mobile';
import { MobileBottomBar } from '../components/mobile-bottom-bar/mobile-bottom-bar';
import { MobileTopbar } from '../components/mobile-topbar/mobile-topbar';
import { LandingPage } from '../components/landing-page/landing-page';
import { MobileLayout } from '../components/mobile-layout/mobile-layout';
import { MobileMap } from '../components/mobile-map/mobile-map';
import { RatingPage } from '../components/rating-page/rating-page';
import { Final } from '../components/final/final';

export const Pages = observer(() => {
  const { stage, isInitialized, isFetching } = useStore();
  const isLanding = stage === Stage.LANDING;
  const isRegistration = stage === Stage.REGISTRATION;
  const isGame = stage === Stage.GAME;
  const isRatingPage = stage === Stage.RATING;
  const isFinal = stage === Stage.FINAL;
  const mobileVersion = isMobile();
  if (!isInitialized) {
    return <Loader />;
  }

  if (mobileVersion) {
    return (
      <>
        <MobileLayout>
          {isLanding && <LandingPage />}
          {isRatingPage && <RatingPage size={10} />}
          {isFinal && <Final />}
          {isGame && (
            <>
              <MobileTopbar />
              <MobileBottomBar />
              <MobileMap />
            </>
          )}
          {isRegistration && <Registration />}
          <Modals />
        </MobileLayout>
        {isFetching && <Loader />}
      </>
    );
  }

  return (
    <>
      <Layout>
        {isLanding && <LandingPage />}
        {isRatingPage && <RatingPage />}
        {isFinal && <Final />}
        {isGame && (
          <>
            <Topbar />
            <Map />
          </>
        )}
        {isRegistration && <Registration />}
        <Modals />
      </Layout>
      {isFetching && <Loader />}
    </>
  );
});
