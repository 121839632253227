import React, { FC } from 'react';
import cn from 'classnames';

import styles from './map-location.module.css';

export type LocationName = 'corgy' | 'owls' | 'cormorant' | 'tiger' | 'sloth';

type Props = {
  name: LocationName;
  onClick?: (name: LocationName) => void;
  level: number;
  canUpgrade?: boolean;
};

export const MapLocation: FC<Props> = ({
  name,
  onClick,
  level = 1,
  canUpgrade,
}) => {
  return (
    <div
      onClick={() => onClick?.(name)}
      className={cn(
        styles.location,
        styles[name],
        styles[`level-${level}`],
        onClick && styles.clickable,
        canUpgrade && styles.canUpgrade
      )}
    />
  );
};
