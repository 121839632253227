import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import cn from 'classnames';

import styles from './event.module.css';
import { Modal, ModalActions, ModalText, ModalTitle } from '../modal/modal';
import { useStore } from '../../store/app';
import { Button } from '../button/button';
import { ResourceItem } from '../resource-item/resource-item';
import { Resources } from '../../types';
import { isEnoughValue } from '../../utils/is-enough-value';
import { formatResourceValue } from '../../utils/format-resource-value';

enum Step {
  'TEXT',
  'SELECT',
  'RESULT',
}

export const Event = observer(() => {
  const [step, setStep] = useState<Step>(Step.TEXT);
  const [answerResponse, setAnswerResponse] = useState<{
    text: string;
    resources: Resources;
  }>();
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const {
    setModal,
    start,
    currentEvent,
    setCurrentEvent,
    putEventAnswer,
    resources,
  } = useStore();

  if (!currentEvent) {
    return null;
  }

  const { title, text, answers } = currentEvent;
  const handleHide = () => {
    setCurrentEvent(undefined);
    start();
    setModal(undefined);
  };
  const handleSubmitAnswer = async () => {
    if (typeof selectedAnswer !== 'number') {
      return;
    }

    const data = await putEventAnswer(selectedAnswer);

    if (!data) {
      console.log('Can not get answer data');
      return;
    }

    setAnswerResponse(data);
    setStep(Step.RESULT);
  };

  return (
    <Modal onHide={handleHide}>
      {step === Step.TEXT && (
        <>
          <ModalTitle>{title}</ModalTitle>
          <ModalText>{text}</ModalText>
          <ModalActions>
            <Button onClick={() => setStep(Step.SELECT)}>Далее</Button>
          </ModalActions>
        </>
      )}
      {step === Step.SELECT && (
        <>
          <ModalTitle className={styles.title}>Выбери</ModalTitle>
          {answers?.map(({ id, title, requirements }) => {
            const isDisabled = !isEnoughValue(resources!, requirements);
            return (
              <div key={id} className={styles.answer}>
                <button
                  className={cn(
                    styles.answerButton,
                    id === selectedAnswer && styles.selected
                  )}
                  onClick={() => setSelectedAnswer(id)}
                  disabled={isDisabled}
                >
                  {title}
                </button>
                <div
                  className={cn(
                    styles.resources,
                    isDisabled && styles.disabled
                  )}
                >
                  {requirements.map(({ id, value }) => (
                    <ResourceItem
                      key={id}
                      resourceId={id}
                      value={formatResourceValue(-value)}
                      variant="small"
                      danger={isDisabled}
                    />
                  ))}
                </div>
              </div>
            );
          })}
          <ModalActions>
            <Button
              onClick={handleSubmitAnswer}
              disabled={typeof selectedAnswer !== 'number'}
              variant="success"
            >
              ОК
            </Button>
          </ModalActions>
        </>
      )}
      {step === Step.RESULT && (
        <>
          <ModalTitle>{title}</ModalTitle>
          <ModalText>{answerResponse?.text}</ModalText>
          <ModalActions>
            <div className={cn(styles.resources && styles.resultResources)}>
              {answerResponse?.resources.map(({ id, value }) => (
                <ResourceItem
                  key={id}
                  resourceId={id}
                  value={formatResourceValue(value)}
                  variant="small"
                />
              ))}
            </div>
            <Button onClick={handleHide}>Понятненько</Button>
          </ModalActions>
        </>
      )}
    </Modal>
  );
});
