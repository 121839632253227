import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useState } from 'react';

import styles from './modal-with-paging.module.css';
import { Modal, ModalActions, ModalText, ModalTitle } from '../modal/modal';
import { Button } from '../button/button';

type Props = {
  title: string;
  pages: ReactNode[];
  successButtonText?: string;
  closable?: boolean;
  onHide?: () => void;
};

export const ModalWithPaging: FC<Props> = observer(
  ({ title, pages, successButtonText, closable, onHide }) => {
    const [step, setStep] = useState<number>(0);
    const hasSuccessButton = Boolean(successButtonText);
    const isLastStep = step === pages.length - 1;
    const isLastStepButtonVisible = hasSuccessButton && isLastStep;
    return (
      <Modal onHide={onHide} closable={closable}>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{pages[step]}</ModalText>
        <ModalActions>
          <div className={styles.buttons}>
            {isLastStepButtonVisible ? (
              <Button onClick={onHide}>{successButtonText}</Button>
            ) : (
              <>
                <button
                  onClick={() => setStep(step - 1)}
                  className={styles.buttonLeft}
                  style={{ visibility: step > 0 ? 'visible' : 'hidden' }}
                />
                <button
                  onClick={() => setStep(step + 1)}
                  className={styles.buttonRight}
                  style={{
                    visibility: step < pages.length - 1 ? 'visible' : 'hidden',
                  }}
                />
              </>
            )}
          </div>
        </ModalActions>
      </Modal>
    );
  }
);
