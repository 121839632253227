import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '../../store/app';
import { ModalWithPaging } from '../modal-with-paging/modal-with-paging';

export const Rules = observer(() => {
  const { setModal, start } = useStore();
  const handleHide = () => {
    start();
    setModal(undefined);
  };
  return (
    <ModalWithPaging
      title="Правила"
      successButtonText="Понятно"
      onHide={handleHide}
      pages={[
        <>
          <strong>
            До столкновения Ледника с поселением осталось 5 минут.
          </strong>
          <br />
          Твоя задача − поднять уровень цивилизации на максимум, чтобы спасти
          арктическую общину от катастрофы.
          <br />
          <strong>Для этого ты можешь прокачивать строения поселения:</strong>
          <br />
          - Платформу «умный город» (IT-технологии)
          <br />
          - Полосатый цех (аддитивные технологии 3D печати)
          <br />
          - Бакланий ветропарк (ветроэнергетика)
          <br />
          - Знахарский пункт (ядерная медицина)
          <br />- Экокоммуну ленивцев (обращение с отходами 1 и 2 класса)
          <br />
          <strong>Кликай на объекты на карте и изучай информацию!</strong>
        </>,
        <>
          Строения приносят ресурсы:
          <br />
          - еду
          <br />
          - научные технологии
          <br />
          - увеличивают популяцию
          <br />
          Ресурсы необходимы для улучшения строений или действий на события,
          которые постоянно происходят в поселении.
          <br />
          Каждый новый уровень строения будет увеличивать главный показатель −{' '}
          <strong>уровень цивилизации</strong> (иконка со стрелками).
        </>,
        <>
          <strong>На уровень цивилизации</strong> также влияют неожиданные
          события. Так что запасись стратегией, тактикой и везением − ведь в
          Арктике каждый твой выбор не останется без последствий.
          <br />
          Также для тебя доступен <strong>рынок</strong> для обмена ресурсами и{' '}
          <strong>правила (иконка шестеренки)</strong>. Если необходимо, можешь
          поставить игру на <strong>паузу (иконка плей)</strong>, чтобы всё
          изучить.
          <br />
          <br />
          <strong>Понятно, что осталось всего 5 минут?!</strong>
        </>,
      ]}
    />
  );
});
