import React, { FC } from 'react';

import styles from './exchange.module.css';
import { ResourceItem } from '../resource-item/resource-item';
import { ResourceId } from '../../types';

type Props = {
  fromResourceId: ResourceId;
  toResourceId: ResourceId;
  value: number;
  minValue?: number;
  maxValue: number;
  multiplier: number;
  handleIncrease: () => void;
  handleDecrease: () => void;
  onChange: (value: number) => void;
};

export const Exchange: FC<Props> = ({
  fromResourceId,
  toResourceId,
  value,
  minValue = 0,
  maxValue,
  multiplier,
  handleDecrease,
  handleIncrease,
  onChange,
}) => {
  const canIncrease = value + multiplier <= maxValue;
  const canDecrease = value > minValue;

  return (
    <div className={styles.exchange}>
      <div className={styles.resources}>
        <ResourceItem resourceId={fromResourceId} value={value} />
        <ResourceItem
          resourceId={toResourceId}
          value={Math.floor(value / multiplier)}
        />
      </div>
      <div className={styles.slider}>
        <button
          className={styles.rangeDecrease}
          onClick={handleDecrease}
          disabled={!canDecrease}
        />
        <input
          type="range"
          className={styles.range}
          min={minValue}
          max={maxValue}
          value={value}
          onChange={event => {
            onChange(Number(event.target.value));
          }}
          step={multiplier}
        />
        <button
          className={styles.rangeIncrease}
          onClick={handleIncrease}
          disabled={!canIncrease}
        />
      </div>
    </div>
  );
};
