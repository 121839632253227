import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
// import cn from 'classnames';

import styles from './final.module.css';
import { Modal, ModalTitle } from '../modal/modal';
import { Rating } from '../rating/rating';
import { useStore } from '../../store/app';
import {
  conditions,
  domain,
  vacancies,
  voteRosatom,
} from '../../constants/links';
import { isMobile } from '../../utils/is-mobile';
import { Button } from '../button/button';

const Block: FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className={styles.block}>
      <Button className={styles.blockTitle} noAction>
        {title}
      </Button>
      <div className={styles.blockContent}>{children}</div>
    </div>
  );
};

export const Final = observer(() => {
  const { setModal, restart, final, openRating } = useStore();
  const handleHide = () => {
    setModal(undefined);
  };

  if (!final) {
    return null;
  }

  const finalText = `${final.status.trim()}. ${final.text} Мой рейтинг: ${
    final.score
  }`;
  const tgShareLink = `//t.me/share/url?url=${domain}&text=${finalText}`;
  const vkShareLink = `https://vk.com/share.php?url=${domain}&title=${finalText}`;

  if (isMobile()) {
    return (
      <div className={styles.modalWrapper}>
        <Modal
          className={styles.mobile}
          size="small"
          align="center"
          onHide={handleHide}
          block
        >
          <ModalTitle className={styles.title}>{final.status}</ModalTitle>
          <div className={styles.rating}>
            <Rating isMobile score={final.score} />
          </div>
          <div className={styles.position}>
            <div className={styles.positionValue}>Место: {final.position}</div>
          </div>
          <div className={styles.text}>{final?.text}</div>
          <div className={styles.share}>
            <Block title="Поделиться">
              <div className={styles.shareLinks}>
                <a
                  href={tgShareLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.tg}
                />
                <a
                  href={vkShareLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.vk}
                />
              </div>
            </Block>
          </div>
          <div className={styles.prizes}>
            <Block title="Награда">
              <div className={styles.prizeContent}>
                <div className={styles.prizesTop}>До 31 октября</div>
                Играй, голосуй и получи поездку к Северному Ледовитому океану
                или один из 30 трофеев от Росатома
              </div>
            </Block>
          </div>
          <div className={styles.buttons}>
            <Button onClick={restart}>Играть еще раз</Button>
            <Button onClick={openRating}>Рейтинг игроков</Button>
            <Button
              href="https://t.me/addstickers/ArcticGame"
              target="_blank"
              rel="noreferrer"
              className={styles.stickers}
              multiline
            >
              Получить стикеры&nbsp;Telegram
            </Button>
          </div>
          <a
            href={voteRosatom}
            target="_blank"
            rel="noreferrer"
            className={styles.rosatomBtn}
          ></a>

          <div className={styles.footer}>
            <div>
              <a href={conditions}>Условия розыгрыша</a>
              <a href={vacancies} target="_blank" rel="noreferrer">
                Вакансии
              </a>
            </div>
            <div className={styles.copy}>© Росатом 2022 | VILLKA </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <Modal size="large" align="center" onHide={handleHide} withAnimals>
      <ModalTitle className={styles.title}>{final.status}</ModalTitle>
      <div className={styles.row}>
        <div className={styles.left}>
          <Rating isHuge score={final.score} />
          <div className={styles.position}>
            <div>Место:</div>
            <div className={styles.positionValue}>{final.position}</div>
          </div>

          <div className={styles.share}>
            <Block title="Поделиться">
              <div className={styles.shareLinks}>
                <a
                  href={tgShareLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.tg}
                />
                <a
                  href={vkShareLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.vk}
                />
              </div>
            </Block>
          </div>
        </div>
        <div>
          <div className={styles.text}>{final?.text}</div>
          <div className={styles.row}>
            <div>
              <div className={styles.prizes}>
                <Block title="Награда">
                  <div className={styles.prizeContent}>
                    <div className={styles.prizesTop}>До 31 октября</div>
                    Играй, голосуй и получи поездку к Северному Ледовитому
                    океану или один из 30 трофеев от Росатома
                  </div>
                </Block>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button onClick={restart}>Играть еще раз</Button>
              <Button onClick={openRating}>Рейтинг игроков</Button>
              <Button
                href="https://t.me/addstickers/ArcticGame"
                target="_blank"
                rel="noreferrer"
                className={styles.stickers}
                multiline
              >
                Получить стикеры&nbsp;Telegram
              </Button>
            </div>
          </div>
        </div>
      </div>
      <a
        href={voteRosatom}
        target="_blank"
        rel="noreferrer"
        className={styles.rosatomBtn}
      ></a>

      <div className={styles.footer}>
        <a href={conditions}>Условия розыгрыша</a>
        <a href={vacancies} target="_blank" rel="noreferrer">
          Вакансии
        </a>
        <div className={styles.copy}>© Росатом 2022 | VILLKA </div>
      </div>
    </Modal>
  );
});
