import React, { FC } from 'react';

import styles from './layout.module.css';

export const Layout: FC = ({ children }) => {
  return (
    <div className={styles.layoutWrapper}>
      <div className={styles.layout}>{children}</div>
    </div>
  );
};
