import { Name } from '../components/map-npc/map-npc';
import { TooltipPosition } from '../components/tooltip/tooltip';

export const NPC: {
  name: Name;
  text?: string;
  tooltipPosition?: TooltipPosition;
}[] = [
  {
    name: 'alien',
    text:
      'Дорогуша, никаких пришельцев и рептилоидов, конечно, не существует. Я — плод твоей фантазии. Может быть, ты также безумен, как саблезубые корги? Эти всегда поддерживают даже самые безбашенные эксперименты. Они всегда за науку, пусть иной раз и за безумную.',
  },
  {
    name: 'cave',
    text:
      'Рррр...Вася был неправ — неправильно перевозил и утилизировал отходы I и II класса...Неэкологичных здесь никто не любит, спроси у ленивцев. Те ещё экопанки и активисты, легко наведут кипиш на тему экологии. Но вежливо.',
    tooltipPosition: 'bottom',
  },
  {
    name: 'mammoth',
    text:
      'Стоим тут, самовыражаемся, никто нас не замечает... Может, надо было присоединиться к бакланам-рейверам? Они у нас про свободу творчества и прочий полёт фантазии.',
  },
  {
    name: 'robot',
    text:
      'Эволюционируй с умом! А то так и не узнаешь, что там было в этой далёкой галактике. Вот совы-гики точно знают, что это может быть — они обожают всё технологичное и максимально цифровое. Искусственный интеллект, онлайн-платформы и продуманство, облегчающее жизнь — совы любят кодить и разрабатывать новое ПО. Будь как совы.',
    tooltipPosition: 'bottom',
  },
  {
    name: 'selfie',
    text:
      'Между прочим, достижения технологий в поселении — это не только социальные сеточки для селфи, но и мощнейшее 3D-производство. На нём саблезубые тигры напечатали мне телефонный девайс. Тигры трудяги и совсем не любят отвлекаться от работы и своего ice3D-принтера. Кажется, это единственное, что интересует их в жизни.',
  },
  {
    name: 'lochness',
    text:
      'Отлично, можно плескаться в прохладной водичке и не переживать, что кто-то скинет в воду ртутьсодержащие элементы, отходы мышьяка, асбестовую пыль, синтетические и минеральные масла. А вот до того, как ленивцы взялись за переработку, СКИДЫВАЛИ. Поэтому мы теперь такие...',
    tooltipPosition: 'bottom',
  },
];
