import React, { FC } from 'react';
import cn from 'classnames';

import { ResourceId } from '../../types';
import science from './science.png';
import population from './population.png';
import food from './food.png';

import styles from './resource-item.module.css';

type Props = {
  resourceId: ResourceId;
  value?: string | number;
  subValue?: string;
  variant?: 'small' | 'normal';
  danger?: boolean;
  isMobile?: boolean;
};

const resourceImageMap: Record<ResourceId, { alt: string; src: string }> = {
  r1: {
    alt: 'популяция',
    src: population,
  },
  r2: {
    alt: 'еда',
    src: food,
  },
  r3: {
    alt: 'наука',
    src: science,
  },
};

export const ResourceItem: FC<Props> = ({
  resourceId,
  value,
  subValue,
  variant = 'normal',
  danger,
  isMobile,
}) => {
  const isValidValue = typeof value === 'number' || typeof value === 'string';
  return (
    <div
      className={cn(
        styles.resource,
        variant === 'small' && styles.small,
        danger && styles.danger,
        isMobile && styles.mobile
      )}
    >
      <img className={styles.image} {...resourceImageMap[resourceId]} />
      {isValidValue && <div className={styles.value}>{value}</div>}
      {subValue && <div className={styles.subValue}>{subValue}</div>}
    </div>
  );
};
