import React, { useEffect, useState } from 'react';

import styles from './loader.module.css';

export const Loader = ({ debounceTimeout = 250 }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), debounceTimeout);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!showLoader) {
    return null;
  }

  return <div className={styles.loader} />;
};
