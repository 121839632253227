import { LocationName } from '../components/map-location/map-location';
import { BuildingId } from '../types';

export const locations: Record<BuildingId, { name: LocationName }> = {
  b1: {
    name: 'owls',
  },
  b2: {
    name: 'tiger',
  },
  b3: {
    name: 'cormorant',
  },
  b4: {
    name: 'corgy',
  },
  b5: {
    name: 'sloth',
  },
};
