import React, { FC } from 'react';

import { Resources } from '../../types';
import { ResourceItem } from '../resource-item/resource-item';

import styles from './resources-block.module.css';

export const ResourcesBlock: FC<{
  resources: Resources;
  valueMapper: (value: number) => string;
}> = ({ resources, valueMapper }) => {
  return (
    <div className={styles.resourcesBlock}>
      {resources.map(({ id, value }) => (
        <ResourceItem
          key={id}
          resourceId={id}
          value={valueMapper ? valueMapper(value) : value}
          variant="small"
        />
      ))}
    </div>
  );
};
