import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import cn from 'classnames';

import styles from './registration-form.module.css';
import { User } from '../../types/user';
import { Button } from '../button/button';
import { ModalActions } from '../modal/modal';
import { Input } from '../input/input';
import { conditions } from '../../constants/links';

type Props = {
  onSubmit: (data: User) => void;
};

interface UserForm extends User {
  conditions: boolean;
}

export const RegistrationForm: FC<Props> = ({ onSubmit }) => {
  const validate = ({ name, email, conditions }: UserForm) => {
    const errors = {
      name: name ? undefined : 'Имя обязательно',
      email: email ? undefined : 'Email обязателен',
      conditions: conditions ? undefined : 'Принять условия обязательно',
    };
    return JSON.parse(JSON.stringify(errors));
  };

  return (
    <Formik<UserForm>
      initialValues={{ name: '', email: '', conditions: false }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, isSubmitting, handleChange, errors, values }) => {
        return (
          <Form className={styles.form} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Придумай игровое имя:</Form.Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
                autoComplete="name"
              />
              {/* <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Впиши e-mail:</Form.Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
                autoComplete="email"
              />
              {/* <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <div className={styles.checkbox}>
                  <input
                    className={cn(
                      styles.checkboxInput,
                      errors.conditions && styles.error
                    )}
                    type="checkbox"
                    name="conditions"
                    value={values.conditions as any}
                    onChange={handleChange}
                  />
                  <span className={styles.checkboxText}>
                    Нажимая кнопку «Готово» вы соглашаетесь с{' '}
                    <a href={conditions} target="_blank" rel="noreferrer">
                      «Условиями розыгрыша»
                    </a>
                    , а также даёте согласие на хранение, обработку и передачу
                    третьим лицам информации, указанной в форме.
                  </span>
                </div>
              </Form.Label>
            </Form.Group>

            <ModalActions>
              <Button type="submit" disabled={isSubmitting}>
                Готово
              </Button>
            </ModalActions>
          </Form>
        );
      }}
    </Formik>
  );
};
