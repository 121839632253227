import React, { FC } from 'react';
import cn from 'classnames';

import { charactersData } from '../../constants/characters';

import styles from './avatar.module.css';
import { CharacterId } from '../../types';
import img1 from './1.png';
import img2 from './2.png';
import img3 from './3.png';

type Props = { id: CharacterId; selected?: boolean; isMobile?: boolean };

const imagesMap = {
  1: img1,
  2: img2,
  3: img3,
};

const Border: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="160"
    height="160"
    viewBox="0 0 120 120"
  >
    <path
      fill="currentColor"
      d="M47.5 1.5c-6.1 1.3-20.2 7.1-19.4 8 .3.2 2.4-.2 4.7-1.1C40.1 5.9 50.2 5 58.3 6c22.6 2.8 41.2 17.5 49.4 38.7 3.4 8.9 3.6 21.1.5 29-1.1 2.9-1.8 5.3-1.7 5.3 1.2 0 7-10 8.7-15.1 1.6-4.7 1.9-7.9 1.6-15.2-.3-8.4-.7-10.1-4.1-17C101.4 8.9 73.3-4.1 47.5 1.5z"
    />
    <path
      fill="currentColor"
      d="M32.5 11.4C18.1 15.9 8.3 26 3.5 41.3-1.6 57.2-.5 71.9 6.7 87c4.1 8.5 15.5 20.7 23.3 24.9l4.5 2.5-5.7-5.3c-7.1-6.5-13-15.8-16.5-25.8-3.8-11.1-3.8-25.5 0-36.6 6.5-18.9 18.8-31.1 35.5-35.1l5.7-1.4H45c-4.7-.1-10.3.5-12.5 1.2z"
    />
    <path
      fill="currentColor"
      d="M118.2 63.7C115 79.4 101.9 95.9 87 103.2c-16.6 8.2-34.7 8.7-49.4 1.5-4.6-2.3-9-5.5-11.9-8.6-2.6-2.8-4.7-4.8-4.7-4.4 0 .3 1.4 3.2 3.1 6.4 4.1 7.6 11.2 14.2 19.7 18.2 6.2 3 7.5 3.2 17.2 3.2 13.3 0 22.3-2.7 33-10 15.6-10.6 26.7-30.7 25.8-46.7l-.3-5.3-1.3 6.2z"
    />
  </svg>
);

export const Avatar: FC<Props> = ({ id, selected, isMobile }) => {
  const name = charactersData[String(id) as keyof typeof charactersData].name;

  return (
    <div className={cn(styles.avatar, isMobile && styles.mobile)}>
      <Border className={cn(styles.border, selected && styles.selected)} />
      <img
        src={imagesMap[(String(id) as unknown) as keyof typeof imagesMap]}
        alt={name}
      />
    </div>
  );
};
